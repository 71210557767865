<template>
  <div class="page-container">
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <!-- name -->
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Tên kiểu người dùng <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
                :custom-messages="customMessages"
              >
                <b-form-input
                  id="basicInput"
                  v-model="userTypeNew.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
          >
            <!-- sign-name -->
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Mô tả</label>
              <b-form-input
                id="basicInput"
                v-model="userTypeNew.description"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div class="d-flex">
      <b-col
        class="permission-content mr-2"
      >
        <liqui-tree
          ref="tree1"
          :key="dataPermission[0].id"
          class="tree-mini"
          :data="dataPermission[0]"
          :options="options"
        >
        </liqui-tree>
      </b-col>
      <b-col
        class="permission-content ml-1"
      >
        <liqui-tree
          ref="tree2"
          :key="dataPermission[1].id"
          class="tree-mini"
          :data="dataPermission[1]"
          :options="options"
        >
        </liqui-tree>
      </b-col>
    </div>

    <!-- Action Buttons -->
    <div class="buton-group mt-1">
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="handleCreateUserType"
      >
        Lưu lại
      </b-button>
      <b-button
        variant="outline-primary"
        type="reset"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :to="{ name: 'list-user-type' }"
      >
        Quay lại
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'

import LiquiTree from 'liquor-tree'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    LiquiTree,
    BButton,
  },
  data() {
    return {
      options: {
        checkbox: true,
        propertyNames: {
          text: 'name',
          children: 'features',
          value: 'value',
        },
      },
      userTypeNew: {
        description: '',
        name: '',
        features: [],
      },
      userTypeId: null,
      customMessages: {
        required: 'Kiểu người dùng là bắt buộc',
      },
      dataUserTypeDetail: {},
    }
  },
  computed: {
    ...mapGetters('userType', ['dataPermission']),
  },
  watch: {
    dataUserTypeDetail: {
      immediate: true,
      handler(val) {
        if (val && this.$route.params.id) {
          this.userTypeNew = {
            description: this.dataUserTypeDetail.description,
            name: this.dataUserTypeDetail.name,
          }
        }
      },
    },
  },
  mounted() {
    this.doFetchUserTypePermission(this.userTypeId)
    if (this.$route.params.id) {
      this.doFetchUserTypePermission(this.userTypeId)
    }
  },
  created() {
    if (this.$route.params.id) {
      this.userTypeId = this.$route.params.id
      this.fetchDetaiUserType(this.userTypeId)
    }
  },
  methods: {
    ...mapActions('userType', ['doFetchUserTypePermission']),

    // Lấy dữ liệu chi tiết kiểu người dùng
    async fetchDetaiUserType(id) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_USERTYPE}${id}`)
      this.dataUserTypeDetail = data
    },

    // create new User Type
    async handleCreateUserType() {
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.createUserType()
        }
      })
    },
    // Thêm kiểu người dùng
    async  createUserType() {
      // lấy danh sách gán
      const pers = []
      // danh sách gán Sở
      const selectTree1 = this.$refs.tree1.find({}, true)
      const selectFeature1 = selectTree1.filter(x => x.parent != null && x.children.length > 0 && (x.states.checked === true || x.states.indeterminate === true))
      const selectNonFeature1 = selectTree1.filter(x => x.parent != null && x.children.length > 0 && (x.states.checked === false))
      selectFeature1.forEach(element => {
        const feature = {
          parentId: element.parent.id,
          id: element.id,
          name: element.data.text,
          value: 0,
        }
        element.children.forEach(child => {
          if (child.children.length === 0 && child.states.checked === true) { feature.value += child.data.value }
        })
        pers.push(feature)
      })
      selectNonFeature1.forEach(element => {
        const nonFeature = {
          parentId: element.parent.id,
          id: element.id,
          name: element.data.text,
          value: 0,
        }
        pers.push(nonFeature)
      })
      // danh sách gán Doanh nghiệp
      const selectTree2 = this.$refs.tree2.find({}, true)
      const selectFeature2 = selectTree2.filter(x => x.parent != null && x.children.length > 0 && (x.states.checked === true || x.states.indeterminate === true))
      const selectNonFeature2 = selectTree2.filter(x => x.parent != null && x.children.length > 0 && (x.states.checked === false))
      selectFeature2.forEach(element => {
        const feature = {
          parentId: element.parent.id,
          id: element.id,
          name: element.data.text,
          value: 0,
        }
        element.children.forEach(child => {
          if (child.children.length === 0 && child.states.checked === true) { feature.value += child.data.value }
        })
        pers.push(feature)
      })
      selectNonFeature2.forEach(element => {
        const nonFeature = {
          parentId: element.parent.id,
          id: element.id,
          name: element.data.text,
          value: 0,
        }
        pers.push(nonFeature)
      })
      // cập nhật kiểu người dùng
      let response = null
      this.userTypeNew.features = pers
      if (this.$route.params.id) {
        const payload = {
          ...this.userTypeNew,
          id: this.$route.params.id,
        }
        response = await axiosApiInstance.put(ConstantsApi.UPDATE_USERTYPE, payload)
        if (response.status === 200) {
          this.$root.$bvToast.toast('Cập nhật thành công', {
            title: 'Thông báo', variant: 'success', toaster: this.$toastPosition, solid: true,
          })
          this.$router.push({ name: 'list-user-type' })
        } else {
          this.$root.$bvToast.toast(`${response.data?.errors[0]}`, {
            title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
          })
        }
      }
      if (!this.$route.params.id) {
        response = await axiosApiInstance.post(ConstantsApi.CREATE_USERTYPE, this.userTypeNew)
        if (response.status === 200) {
          this.$root.$bvToast.toast('Tạo thành công', {
            title: 'Thông báo', variant: 'success', toaster: this.$toastPosition, solid: true,
          })
          this.$router.push({ name: 'list-user-type' })
        } else {
          this.$root.$bvToast.toast(`${response.data?.errors[0]}`, {
            title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/tree/tree.scss";
.permission-content {
  border: 1px solid #a6a8b0;
  box-sizing: border-box;
  border-radius: 6px;
  height: 100%;
  .tree-checkbox {
    border: 1px solid #a6a8b0;
  }
}
</style>
